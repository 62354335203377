import { Offer } from '@/components/features/Offer';
import { Session } from 'next-auth';
import { CroatiaCarsInfo } from '../components/features/CroatiaCarsInfo';
import { Forbidden } from '../components/shared/Forbidden';
import { Layout } from '../components/shared/Layout/Layout';
import { useAuth } from '../hooks/useAuth';
import { useBrand } from '../hooks/useBrand';
import { usePreferences } from '../contexts/PreferencesContext';
import { useKeys } from '../contexts/KeysContext';
import { IUserData } from '../interfaces/IUserData';

// eslint-disable-next-line consistent-return
const IndexPage = ({ session }: { session: Session | null }) => {
  const isValidAuth = useAuth(session);
  const { preferences } = usePreferences();
  const env = useBrand();

  const keys = useKeys();
  const userData = keys.getKey('userData') as IUserData;
  const showSalary = keys.getKey('showSalary') as boolean;

  if (!isValidAuth) {
    return <Forbidden />;
  } else if (env.unknown) {
    return null;
  } else if (env.offer) {
    return <Offer />;
  } else if (userData.grant && session && !showSalary) {
    window.location.replace('/stocks');

    return null;
  }

  if (env.config.carsByDefault) {
    return (
      <Layout details={[0, 1, 2]} country="hr" limitLanguages={env.languages}>
        <CroatiaCarsInfo />
      </Layout>
    );
  } else {
    const country = preferences.activeCountry ?? env.config.defaultCountry;

    window.location.replace(`/${country}${window.location.hash}`);
  }

  return null;
};

export default IndexPage;
